import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DominiElencoComponent } from './domini-elenco/domini-elenco.component';
import { DominiSchedaComponent } from './domini-scheda/domini-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { ModalAnagraficaComponent } from './domini-scheda/modal-anagrafica/modal-anagrafica.component';
import { AnagraficaModule } from '../anagrafica/anagrafica.module';
import { ModalRinnovoDominioComponent } from './domini-elenco/modal-rinnovo-dominio/modal-rinnovo-dominio.component';
import { ModalBaseRinnovoDominioComponent } from './domini-elenco/common/modal-base-rinnovo-dominio/modal-base-rinnovo-dominio.component';



@NgModule({
  declarations: [
    DominiElencoComponent,
    DominiSchedaComponent,
    ModalAnagraficaComponent,
    ModalRinnovoDominioComponent,
    ModalBaseRinnovoDominioComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AnagraficaModule
  ],
  exports: [
      ModalRinnovoDominioComponent,
  ]
})
export class DominiModule { }
