import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { DominiService } from '../../../domini.service';
import { Dominio } from '../../../dominio';

@Component({
  selector: 'app-modal-base-rinnovo-dominio',
  templateUrl: './modal-base-rinnovo-dominio.component.html',
  styleUrls: ['./modal-base-rinnovo-dominio.component.scss']
})
export class ModalBaseRinnovoDominioComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Output() onCancelEmitter = new EventEmitter();
  @Output() onSubmitEmitter = new EventEmitter<boolean>();

  modalReference: any;
  titoloModal: string = "";
  arrayCampi: any[any];
  myForm: FormGroup;
  waitModalContent: boolean = true;
  loading: boolean = false;
  maxAnni = [];
  myDominio: Dominio;

  constructor(
    private modalService: NgbModal,
    private service: DominiService,
    public mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService

  ) { }

  ngOnInit(): void {
  }


  openModal(idItem: number): void {
    if (idItem < 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
    this.waitModalContent = true;

    this.service.getItem(idItem)
    .subscribe(data => {
      this.myDominio = data;

      this.titoloModal = " - Dominio " + data.indirizzo;

      this.arrayCampi = {
        'numAnni' : {'id' : 'numAnni', 'nome': 'Rinnova per', 'type': 'NUM', 'required': true},
        'inviaEmail' : {'id' : 'inviaEmail', 'nome': 'Invia email di notifica', 'type': 'BOL'},
        'id' : {'id' : 'id'},
        'note' : {'id' : 'note', 'nome': 'Note interne sul rinnovo', 'note': 'Queste note sono ad uso interno e no verranno inviate al cliente', 'type': 'TXA'},
        'action' : {'id' : 'action'},
      }

      this.maxAnni = [];
      for (let i = 1; i <= 5; i++) {
        if (i == 1) {
          var titolo = "anno";
        }
        else {
          var titolo = "anni";
        }
        titolo += " (" + this.mainEngineService.toCurrency(this.myDominio.costoRinnovo * i)+" )";
        this.maxAnni.push({'id': i, 'denominazione' : i+" "+titolo});
      }

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);
      this.myForm.patchValue({ 'id': idItem });
      this.myForm.patchValue({ 'action': 'rinnovo' });
      this.myForm.patchValue({ 'numAnni': 1 });
      this.myForm.patchValue({ 'inviaEmail': true });

      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
      this.waitModalContent = false;
    });
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendRinnovo(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.onSubmitEmitter.emit(true);
        this.notificationsService.show('not_OpOk');
        this.onCancel();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.loading = false;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }
}
