/******
Versione 1.0.20210403
******/

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ButtonService } from './button.service';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {
  @Input() config: any;
  @Input() type: string;
  @Input() actValue: any;
  @Input() loading: boolean = false;
  @Input() forceDisabled: boolean = false;
  @Input() onClick: () => void;

  colorClass: string;
  icon: string;
  mainClass = '';
  actionTooltip: string;
  testo = '';
  clickAction = '';
  hideText: boolean = false;
  ML: boolean = false;
  size: string = '';
  lockOnLoading: boolean = false;
  stillLoading: boolean = false;

  constructor(
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void {
    if (this.config) {
      this.type = this.config.type;
      this.hideText = this.config.hideText;
    }
    this.initButton();
  }

  initButton() {

    switch (this.type) {
      case "opInCorso":
        this.colorClass = 'secondary';
        this.icon = 'bx bxs-hourglass-bottom';
        this.actionTooltip = 'Attendere il termine delle operazioni';
        this.testo = 'Operazione in corso, attendere...';
        this.lockOnLoading = true;
        break;

      case "cerca":
        this.colorClass = 'primary';
        this.icon = 'bx bx-search-alt';
        this.actionTooltip = 'Ricerca';
        this.testo = 'Cerca';
        break;

      case "reset":
        this.colorClass = 'secondary';
        this.icon = 'bx bx-reset';
        this.actionTooltip = 'Resetta i campi del form';
        this.testo = 'Reset';
        break;

      case "gestione":
        this.colorClass = 'secondary';
        this.icon = 'bx bxs-edit';
        this.actionTooltip = 'Gestione elementi';
        this.testo = 'Gestione';
        break;

      case "conferma":
        this.colorClass = 'success';
        this.icon = 'bx bxs-check-circle';
        this.actionTooltip = 'Salva le modifiche e torna all\'elenco';
        this.testo = 'Conferma';
        break;

      case "confermaNuovo":
        this.colorClass = 'success';
        this.icon = 'bx bxs-add-to-queue';
        this.actionTooltip = 'Salva le modifiche e crea un nuovo elemento';
        this.testo = 'Conferma e nuovo';
        this.mainClass = " marginLeft";
        break;

      case "salva":
        this.colorClass = 'success';
        this.icon = 'bx bxs-save';
        this.actionTooltip = 'Salva le modifiche e continua';
        this.testo = 'Salva e continua';
        this.mainClass = " marginLeft";
        break;

      case "annulla":
        this.colorClass = 'danger';
        this.icon = 'bx bx-window-close';
        this.actionTooltip = 'Annulla le modifiche senza salvare e torna all\'elenco';
        this.testo = 'Annulla';
        this.mainClass = " marginLeft";
        break;

      case "close":
        this.colorClass = 'secondary';
        this.icon = 'bx bx-window-close';
        this.testo = 'Chiudi';
        break;

      case "edit":
        this.colorClass = 'primary';
        this.icon = 'mdi mdi-file-document-edit';
        this.actionTooltip = 'Modifica elemento';
        this.testo = 'Modifica';
        break;

      case "delete":
        this.colorClass = 'danger';
        this.icon = 'mdi mdi-delete';
        this.actionTooltip = 'Elimina elemento';
        this.testo = 'Elimina';
        break;

      case "cantDelete":
        this.colorClass = 'danger';
        this.icon = 'mdi mdi-delete-off-outline';
        this.actionTooltip = 'Impossibile eliminare';
        this.testo = 'Elimina';
        this.mainClass = "lineThrough"
        this.onClick = this.cantDelete;
        break;

      case "visible":
        if (this.actValue) {
          this.colorClass = 'success';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-eye-check';
          this.actionTooltip = 'Elemento visibile';
        } else {
          this.colorClass = 'light';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-eye-off-outline';
          this.actionTooltip = 'Elemento non visibile';
        }
        break;

      case "attivo":
        if (this.actValue) {
          this.colorClass = 'success';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-check-circle';
          this.actionTooltip = 'Elemento attivo';
        } else {
          this.colorClass = 'light';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-checkbox-blank-circle';
          this.actionTooltip = 'Elemento non attivo';
        }
        break;

      case "evidenza":
        if (this.actValue == "1") {
          this.colorClass = 'success';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-star';
          this.actionTooltip = 'Elemento in evidenza';
        } else {
          this.colorClass = 'light';
          this.mainClass = 'btn-rounded';
          this.icon = 'mdi mdi-star-off-outline';
          this.actionTooltip = 'Elemento non in evidenza';
        }
        break;


      case "back":
        this.colorClass = 'info';
        this.icon = 'mdi mdi-backspace';
        this.actionTooltip = 'Torna indietro';
        this.testo = 'Indietro';
        break;

      case "new":
        this.colorClass = 'success';
        this.icon = 'mdi mdi-plus-circle';
        this.actionTooltip = 'Crea nuovo elemento';
        this.testo = 'Nuovo';
        break;

      case "add":
        this.colorClass = 'success';
        this.icon = 'mdi mdi-plus-circle';
        this.actionTooltip = 'Aggiungi elemento';
        this.testo = 'Aggiungi';
        break;

      case "category":
        this.colorClass = 'info';
        this.icon = 'mdi mdi-category';
        this.actionTooltip = 'Gestione delle categorie';
        this.testo = 'Gestione categorie';
        break;

      case "custom":
        this.colorClass = this.config.colorClass ? this.config.colorClass : 'default';
        this.icon = this.config.icon ? this.config.icon : '';
        this.actionTooltip = this.config.actionTooltip ? this.config.actionTooltip : '';
        this.mainClass = this.config.mainClass ? this.config.mainClass : '';
        this.size = this.config.size ? this.config.size : '';
        this.lockOnLoading =  this.config.lockOnLoading ? this.config.lockOnLoading : false;

        break;
    }

    if (this.config) {
      if (this.config.noMargin) {
        this.mainClass += " noMargin";
      } else if (this.config.ML) {
          this.mainClass += " marginLeft";
      }
      if (this.config.mainClass) {
        this.mainClass += " " + this.config.mainClass + " ";
      }

      if (this.config.testo) {
        this.testo = this.config.testo;
      }
      if (this.config.actionTooltip) {
        this.actionTooltip = this.config.actionTooltip;
      }
    }
  }

  cantDelete() {
    this.buttonService.cantDelete();
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if ('actValue' in changes) {
      this.initButton();
        // trigger the flash animation here
    }
    if ('loading' in changes) {
      this.stillLoading = changes['loading'].currentValue;
        // trigger the flash animation here
    }
  }
}
