import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Anagrafica } from './anagrafica';

@Injectable({
  providedIn: 'root'
})
export class AnagraficaService {
  apiUrl: string;
  linkBase: string = "anagrafica";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayCampi: any[any];

  constructor(    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');


    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'denominazione' : {'id' : 'denominazione', 'nome': 'Denominazione', 'required': true},
      'referente' : {'id' : 'referente', 'nome': 'Referente'},
      'piva' : {'id' : 'piva', 'nome': 'P. IVA / Codice fiscale', 'required': true},
      'codiceDestinatario' : {'id' : 'codiceDestinatario', 'nome': 'Codice destinatario'},
      'pec' : {'id' : 'pec', 'nome': 'PEC'},
      'email' : {'id' : 'email', 'nome': 'Email', 'required': true},
      'telefono' : {'id' : 'telefono', 'nome': 'Telefono'},
      'indirizzo' : {'id' : 'indirizzo', 'nome': 'Indirizzo', 'required': true},
      'citta' : {'id' : 'citta', 'nome': 'Città', 'required': true},
      'cap' : {'id' : 'cap', 'nome': 'Cap', 'shortInput': true, 'type': 'TXT', 'required': true},
      'idStato' : {'id' : 'idStato', 'nome': 'Nazione', 'type': 'SEL', 'required': true},
      'note' : {'id' : 'note', 'nome': 'Note', 'tpye': 'TXA'},
    };

  }

  getElenco(): Observable<Anagrafica[]> {
    return this.http.get<Anagrafica[]>(this.apiUrl)
  }

  getElencoPerSelect(): Observable<Anagrafica[]> {
    return this.http.get<Anagrafica[]>(this.apiUrl + 'op/elencoPerSelect')
  }

  getDettagliAnagrafica(idAnagrafica: number): Observable<string> {
    return this.http.get<string>(this.apiUrl + 'op/dettagliAnagrafica?id=' + idAnagrafica)
  }




  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }


  getItem(id: number): Observable<Anagrafica> {
    return this.http.get<Anagrafica>(this.apiUrl + id)
  }

  deleteItem(id: number) {
    return this.http.delete<Anagrafica>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  clickItemButton(button, item) {
    button.value.onClickFunction(item.id);
  }
}
