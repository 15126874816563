import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { CustomOperationsService } from '../custom-operations.service';

@Component({
  selector: 'app-custom-operations',
  templateUrl: './custom-operations.component.html',
  styleUrls: ['./custom-operations.component.scss']
})
export class CustomOperationsComponent implements OnInit {

  constructor(
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private router: Router,
    private service: CustomOperationsService
  ) { }

  ngOnInit(): void {
    this.mainEngineService.updateUrl();
    if (this.mainEngineService.globalUrlArray && (this.mainEngineService.globalUrlArray.length > 1)) {
      switch (this.mainEngineService.globalUrlArray[2]) {
        case 'generaAccount':
          if (this.mainEngineService.globalUrlArray.length !== 5) {
            this.notificationsService.show('error', '', 'Il link utilizzato per questa richiesta non è più valido.<br>Ripetere nuovamente la procedura');
          } else {
            localStorage.clear();
            this.generaAccount(Number(this.mainEngineService.globalUrlArray[3]), this.mainEngineService.globalUrlArray[4]);
          }
        break;


      }
    }
  }

  generaAccount(idUser: number, token: string) {
    this.service.generaAccount(idUser, token)
    .pipe(first())
    .subscribe(
      data => {
        this.notificationsService.show('success', 'La richiesta è stata confermata', 'Riceverai le credenziali di accesso al tuo indirizzo email');
        this.router.navigate(['/']);
      },
      error => {
        this.mainEngineService.errorHandler(error);
        this.router.navigate(['/']);
      }
    );
  }


}
