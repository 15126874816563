import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ElencoComponent } from 'src/app/components/elenco/elenco.component';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { DominiService } from '../domini.service';

@Component({
  selector: 'app-domini-elenco',
  templateUrl: './domini-elenco.component.html',
  styleUrls: ['./domini-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DominiElencoComponent implements OnInit {
  @ViewChild(ElencoComponent) child:ElencoComponent;

  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  idPadre = 0;
  openRinnovoIdDominio: number = -1;

  constructor(
    private service: DominiService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private buttonService: ButtonService
  ) {
    this.myService = this.service;
    let that = this;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'indirizzo', header: 'Indirizzo',    cell: (element: any) => `${element.indirizzo}` },
          { columnDef: 'intestazione', header: 'Intestazione',    cell: (element: any) => `${element.intestazione}` },
          { columnDef: 'dataScadenza', header: 'Scadenza', data: true, backgroundColor: (element: any) => `${element.backgroundDataScadenza}`,  cell: (element: any) => `${element.dataScadenza}` },
          { columnDef: 'dataUltimoRinnovo', header: 'Ultimo rinnovo', data: true,   cell: (element: any) => `${element.dataUltimoRinnovo}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
          customButtons: {
            a1_email: {
              type: 'custom',
              testo: 'Email',
              colorClass: 'info',
              icon: 'mdi mdi-email-send',
              actionTooltip: 'Reinvia email di scadenza',
              onClickFunction: this.reinviaEmail.bind(this),
              condizione: 'dominioInScadenza',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a2_pagamento: {
              type: 'custom',
              testo: 'Rinnova',
              colorClass: 'info',
              icon: 'mdi mdi-autorenew',
              actionTooltip: 'Rinnova questo dominio',
              onClickFunction: this.gestioneRinnovo.bind(this),
              condizione: 'dominioInScadenza',
              thisPointer: that,
              hideText: true,
              ML: true
            }

          }
        }

      });
  }

  ngOnInit(): void {
  }

  reloadElenco() {
    this.child.getElenco();
  }

  resetOpenRinnovoIdDominio(reload: boolean = false) {
    this.openRinnovoIdDominio = -1;

    if (reload) {
      this.reloadElenco();
    }
  }

  gestioneRinnovo(idDominio) {
    this.openRinnovoIdDominio = idDominio;
  }


  reinviaEmail(idDominio) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.reinviaEmail(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.reloadElenco();
          },
          (error) => {
            this.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idDominio);
  }
}
