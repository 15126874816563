import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutRoutes } from '../../../custom/layouts/local-main-layout/main-layout.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(MainLayoutRoutes),
  ],
  declarations: [

  ]
})
export class MainLayoutModule {
 }
