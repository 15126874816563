import { Component, Type } from '@angular/core';
import { Routes } from '@angular/router';
import { AdminGuardGuard } from 'src/app/admin-guard.guard';
import { AuthGuardService } from 'src/app/auth-guard.service';
import { ClientGuardGuard } from 'src/app/client-guard.guard';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { UsersDettaglioComponent } from 'src/app/components/users/users-dettaglio/users-dettaglio.component';
import { UsersElencoComponent } from 'src/app/components/users/users-elenco/users-elenco.component';
import { AnagraficaElencoComponent } from 'src/app/pages/anagrafica/anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from 'src/app/pages/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { DominiElencoComponent } from 'src/app/pages/domini/domini-elenco/domini-elenco.component';
import { DominiSchedaComponent } from 'src/app/pages/domini/domini-scheda/domini-scheda.component';
import { HomepageComponent } from 'src/app/pages/homepage/homepage.component';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { AnagraficaUtenteComponent } from 'src/app/userPages/anagrafica-utente/anagrafica-utente/anagrafica-utente.component';
import { DominiUtenteElencoComponent } from 'src/app/userPages/domini-utente/domini-utente-elenco/domini-utente-elenco.component';

export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: 'anagrafica',
    canActivate: [AdminGuardGuard],
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/lastItem/:id',
    canActivate: [AdminGuardGuard],
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/:id',
    canActivate: [AdminGuardGuard],
    component: AnagraficaSchedaComponent
  },
  {
    path: 'domini',
    canActivate: [AdminGuardGuard],
    component: DominiElencoComponent
  },
  {
    path: 'domini/lastItem/:id',
    canActivate: [AdminGuardGuard],
    component: DominiElencoComponent
  },
  {
    path: 'domini/:id',
    canActivate: [AdminGuardGuard],
    component: DominiSchedaComponent
  },
  {
    path: 'dominiUtente',
    canActivate: [ClientGuardGuard],
    component: DominiUtenteElencoComponent
  },
  {
    path: 'anagraficaUtente',
    canActivate: [ClientGuardGuard],
    component: AnagraficaUtenteComponent
  },
];

