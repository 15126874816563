import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { DominiService } from '../../domini.service';
import { ModalBaseRinnovoDominioComponent } from '../common/modal-base-rinnovo-dominio/modal-base-rinnovo-dominio.component';

@Component({
  selector: 'app-modal-rinnovo-dominio',
  templateUrl: './modal-rinnovo-dominio.component.html',
  styleUrls: ['./modal-rinnovo-dominio.component.scss']
})
export class ModalRinnovoDominioComponent implements OnInit {
  @Input() openRinnovoIdDominio: number;
  @Output() resetOpenRinnovoIdDominio = new EventEmitter<boolean>();
  @ViewChild(ModalBaseRinnovoDominioComponent, {static : true}) child:ModalBaseRinnovoDominioComponent;

  linkBase: string;

  constructor(
    private service: DominiService,
    private mainEngineService: MainEngineService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.linkBase = this.service.linkBase;
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.resetOpenRinnovoIdDominio.emit(null);
  }

  onSubmit(reload: boolean = false) {
    this.resetOpenRinnovoIdDominio.emit(reload);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openRinnovoIdDominio' in changes) {
      if (this.openRinnovoIdDominio > -1) {
        this.linkBase = this.service.linkBase + '/lastItem/' + this.openRinnovoIdDominio;
        this.child.openModal(this.openRinnovoIdDominio);
      }
    }
  }
}
