import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnagraficaUtenteComponent } from './anagrafica-utente/anagrafica-utente.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { AnagraficaModule } from 'src/app/pages/anagrafica/anagrafica.module';



@NgModule({
  declarations: [
    AnagraficaUtenteComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AnagraficaModule
  ]
})
export class AnagraficaUtenteModule { }
