
import { Routes } from '@angular/router';
import { LoginComponent } from 'src/app/components/reservedAccess/login/login.component';
import { RecoverComponent } from 'src/app/components/reservedAccess/recover/recover.component';
import { CustomOperationsComponent } from '../../custom-operations/custom-operations/custom-operations.component';

export const LoginLayoutRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: 'confermaPassword/:userId/:token',
    component: LoginComponent
  },
  {
    path: 'confermaEmail/:userId/:token',
    component: LoginComponent
  },
  {
    path: 'customOperations/:op/:userId/:token',
    component: CustomOperationsComponent
  }
];
