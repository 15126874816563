import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-testata',
  templateUrl: './testata.component.html',
  styleUrls: ['./testata.component.scss']
})
export class TestataComponent implements OnInit {
  @Output() public onBackEmitter = new EventEmitter<void>();

  @Input() titoloScheda: string;
  @Input() hiddenBackButton: boolean = true;
  @Input() pulsanteNuovo: string = '';
  @Input() componentObj: any = '';
  @Input() pulsanteCategorie: string = '';
  @Input() testataButtons: any;

  forceHiddenBackButton: boolean = true;
  arrayPulsanti: any[any];

  constructor(
  ) {
    this.arrayPulsanti = {
      'category' : {'type' : 'category', 'ML' : true},
      'back' : {'type' : 'back', 'ML' : true},
      'new' : {'type' : 'new', 'ML' : true},
    };

  }

  ngOnInit(): void {
    this.updateHiddenBackButton();
  }


  updateHiddenBackButton() {
    this.forceHiddenBackButton = this.hiddenBackButton || (this.onBackEmitter.observers.length <= 0);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('hiddenBackButton' in changes) {
      this.updateHiddenBackButton();
    }
  }

  onBack() {
    this.onBackEmitter.emit(null);
  }

}
