import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'Domini',
      icon: 'fas fa-globe',
      link: '/domini',
    },
    {
      label: 'Anagrafica',
      icon: 'fas fa-users',
      link: '/anagrafica',
    },
];


export const MENU_USER: MenuItem[] = [
  {
    label: 'Domini',
    icon: 'fas fa-globe',
    link: '/dominiUtente',
  },
  {
    label: 'Anagrafica',
    icon: 'fas fa-users',
    link: '/anagraficaUtente',
  },
];

