import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { AnagraficaService } from 'src/app/pages/anagrafica/anagrafica.service';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-modal-anagrafica',
  templateUrl: './modal-anagrafica.component.html',
  styleUrls: ['./modal-anagrafica.component.scss']
})
export class ModalAnagraficaComponent implements OnInit {
  @ViewChild('modal') modal: any;

  @Input() openModalAnagrafica: boolean = false;

  @Output() onSubmitEmitter = new EventEmitter<number>();
  @Output() onCancelEmitter = new EventEmitter<void>();

  modalReference: any;
  myForm: FormGroup;
  arrayCampi: any[any];
  waitModalContent: boolean = true;
  loading = false;

  constructor(
    private modalService: NgbModal,
    private service: AnagraficaService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {
    this.arrayCampi = this.service.arrayCampi;
  }

  ngOnInit(): void {
  }

  openModal() {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
  }

  formReady(ready: boolean) {
    this.waitModalContent = !ready;
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (idAnagrafica) => {
        this.notificationsService.show('not_OpOk');
        this.waitModalContent = true;
        this.loading = false;
        this.modalReference.close();
        this.onSubmitEmitter.emit(idAnagrafica);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openModalAnagrafica' in changes) {
      if (this.openModalAnagrafica) {
        this.openModal();
      }
    }
  }
}
