import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ConfigParams } from '../../class/config-params';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  year: number = new Date().getFullYear();
  configParams: ConfigParams;

  constructor(
    private mainEngineService: MainEngineService,
    private modalService: NgbModal
  ) {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      this.configParams = value;
    });
  }

  ngOnInit(): void {
  }

  openModal(modal: any) {
    this.modalService.open(modal, { size: 'xl', windowClass: 'modal-holder', centered: true });
  }
}
