import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ElencoComponent } from 'src/app/components/elenco/elenco.component';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ModalRinnovoDominioComponent } from 'src/app/pages/domini/domini-elenco/modal-rinnovo-dominio/modal-rinnovo-dominio.component';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { DominiUtenteService } from '../domini-utente.service';

@Component({
  selector: 'app-domini-utente-elenco',
  templateUrl: './domini-utente-elenco.component.html',
  styleUrls: ['./domini-utente-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DominiUtenteElencoComponent implements OnInit {
  @ViewChild(ElencoComponent) child:ElencoComponent;

  columns: any[any];
  options: any[any];
  myService: any;
  openRinnovoIdDominio: number = -1;


  constructor(
    private service: DominiUtenteService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private buttonService: ButtonService
  ) {
    this.myService = this.service;
    let that = this;

    this.columns = [
      { columnDef: 'indirizzo', header: 'Indirizzo',    cell: (element: any) => `${element.indirizzo}` },
      { columnDef: 'dataScadenza', header: 'Scadenza', data: true, backgroundColor: (element: any) => `${element.backgroundDataScadenza}`,  cell: (element: any) => `${element.dataScadenza}` },
      { columnDef: 'dataUltimoRinnovo', header: 'Ultimo rinnovo', data: true,   cell: (element: any) => `${element.dataUltimoRinnovo}` },
      { columnDef: 'costoRinnovo', header: 'Costo rinnovo', currency: 'EUR',  cell: (element: any) => `${element.costoRinnovo}` },
    ];
    this.options = {
      linkBase: this.myService.linkBase,
      customButtons: {
        a1_pagamento: {
          type: 'custom',
          testo: 'Rinnova',
          colorClass: 'info',
          icon: 'mdi mdi-autorenew',
          actionTooltip: 'Rinnova questo dominio',
          onClickFunction: this.gestioneRinnovo.bind(this),
          condizione: 'dominioInScadenza',
          thisPointer: that,
          hideText: true,
          ML: true
        }

      }
    }
  }

  ngOnInit(): void {
  }

  reloadElenco() {
    this.child.getElenco();
  }

  resetOpenRinnovoIdDominio(reload: boolean = false) {
    this.openRinnovoIdDominio = -1;

    if (reload) {
      this.reloadElenco();
    }
  }

  gestioneRinnovo(idDominio) {
    this.openRinnovoIdDominio = idDominio;
  }


}
