import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-rinnovo-dominio-utente',
  templateUrl: './modal-rinnovo-dominio-utente.component.html',
  styleUrls: ['./modal-rinnovo-dominio-utente.component.scss']
})
export class ModalRinnovoDominioUtenteComponent implements OnInit {
  @Input() openRinnovoIdDominio: number;
  @Output() resetOpenRinnovoIdDominio = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
