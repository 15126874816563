import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Anagrafica } from 'src/app/pages/anagrafica/anagrafica';
import { AnagraficaService } from 'src/app/pages/anagrafica/anagrafica.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { AnagraficaUtenteService } from '../anagrafica-utente.service';

@Component({
  selector: 'app-anagrafica-utente',
  templateUrl: './anagrafica-utente.component.html',
  styleUrls: ['./anagrafica-utente.component.scss']
})
export class AnagraficaUtenteComponent implements OnInit {
  myForm: FormGroup;
  myItem: Anagrafica;
  titoloScheda: string = "Caricamento...";
  loading = false;
  linkBase: string;

  constructor(
    private formComponentsService: FormInputService,
    private service: AnagraficaUtenteService,
    private mainService: AnagraficaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private router: Router,
    private componentsService: ComponentsService
  ) { }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.mainService.arrayCampi);
    this.formComponentsService.activateLoader(true);
    this.linkBase = "/";

    this.service.getItem()
    .subscribe(data => {
      this.myItem = data;
      this.titoloScheda = "Modifica Anagrafica"
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate([this.linkBase]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }
}
