import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnagraficaElencoComponent } from './anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from './anagrafica-scheda/anagrafica-scheda.component';
import { AnagraficaFormComponent } from './anagrafica-form/anagrafica-form.component';
import { ModalDettaglioAnagraficaComponent } from './modal-dettaglio-anagrafica/modal-dettaglio-anagrafica.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    AnagraficaElencoComponent,
    AnagraficaSchedaComponent,
    AnagraficaFormComponent,
    ModalDettaglioAnagraficaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
  ],
  exports: [
    AnagraficaFormComponent,
  ]
})
export class AnagraficaModule { }
