/******
Versione 1.0.20210401
******/
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-periodo',
  templateUrl: './campo-periodo.component.html',
  styleUrls: ['./campo-periodo.component.scss']
})
export class CampoPeriodoComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  min: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput_dal() {
    return this.myForm.get(this.config.id + '_dal');
  }

  get objInput_al() {
    return this.myForm.get(this.config.id + '_al');
  }


  onChange() {
    let dal = this.objInput_dal.value;
    let al = this.objInput_al.value;

    if (!dal || !al) {
      return;
    }

    if (dal > al) {
      this.myForm.controls[this.config.id + '_al'].setErrors({'incorrect': true});
    }
    else {
      this.myForm.controls[this.config.id + '_al'].setErrors(null);
    }
  }

  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'periodo';
    }
    if (!this.config.nome) {
      this.config.nome = 'Periodo';
    }
    if (!this.config.icona) {
      this.config.icona = 'calendar';
    }
    if (this.config.minToday) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      this.min = yyyy+"-"+mm+"-"+dd;
    }
  }
}
