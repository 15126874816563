import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { AnagraficaService } from '../../anagrafica/anagrafica.service';
import { DominiService } from '../domini.service';
import { Dominio } from '../dominio';

@Component({
  selector: 'app-domini-scheda',
  templateUrl: './domini-scheda.component.html',
  styleUrls: ['./domini-scheda.component.scss']
})
export class DominiSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: Dominio;
  titoloScheda: string = "Caricamento...";
  arrayAnagrafica: any[any];
  linkBase: string;
  arrayCampi: any[any];
  arrayPulsanti: any[any];
  openModalAnagrafica: boolean = false;
  daAcquistare: boolean = false;
  nuovoElemento: boolean = true;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: DominiService,
    private anagraficaService: AnagraficaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'indirizzo' : {'id' : 'indirizzo', 'nome': 'Indirizzo', 'required': true},
      'idAnagrafica' : {'id' : 'idAnagrafica', 'nome': 'Intestazione', 'required': true, 'type': 'NUM'},
      'daAcquistare' : {'id' : 'daAcquistare', 'nome': 'Da acquistare', 'type': 'BOL', 'note': 'Spuntare se il dominio è ancora da acquistare'},
      'dataAcquisto' : {'id' : 'dataAcquisto', 'nome': 'Data di attivazione', 'required': true, 'type': 'DTA'},
      'costoRinnovo' : {'id' : 'costoRinnovo', 'nome': 'Costo rinnovo', 'required': true, 'type': 'VLT'},
      'noteCliente' : {'id' : 'noteCliente', 'nome': 'Note/descrizione per il cliente'},
      'noteInterne' : {'id' : 'noteInterne', 'nome': 'Note ad uso interno, non visibili per il cliente'},
    };

    this.arrayPulsanti = {
      'crea' : {'type' : 'new', 'testo' : 'Crea nuova anagrafica'},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);
    this.linkBase = this.service.linkBase;

    this.loading = true;

    this.route.params.subscribe(
      (params) => {
        this.anagraficaService.getElencoPerSelect()
        .subscribe(data => {
          this.arrayAnagrafica = data;

          if (params.id != '0') {
            this.nuovoElemento = false;
            this.linkBase += '/lastItem/' + params.id;
            this.service.getItem(params.id)
            .subscribe(data => {
              this.myItem = data;
              this.myForm.patchValue(this.myItem);
              this.titoloScheda = "Modifica elemento - '" + this.myItem.indirizzo + "'";
              this.formComponentsService.activateLoader(false);
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              this.mainEngineService.errorHandler(error);
            });
          }
          else {
            this.titoloScheda = "Inserimento elemento";
            this.myItem = new Dominio;
            this.formComponentsService.activateLoader(false);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.mainEngineService.errorHandler(error);
        });
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  modalForm() {
    this.openModalAnagrafica = true;
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

  onClickDaAcquistare(checked) {
    this.daAcquistare = checked;
    if (checked) {
      this.myForm.get('costoRinnovo').clearValidators();
      this.myForm.get('dataAcquisto').clearValidators();
    }
    else {
      this.myForm.get('costoRinnovo').setValidators([Validators.required]);
      this.myForm.get('dataAcquisto').setValidators([Validators.required]);
    }
    this.myForm.get('dataAcquisto').updateValueAndValidity();
    this.myForm.get('costoRinnovo').updateValueAndValidity();
  }



}
