import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ReservedAccessService } from './components/reservedAccess/reserved-access.service';
import { UserLogged } from './components/reservedAccess/user-logged';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardGuard implements CanActivate {
  constructor(
    private reservedAccessService: ReservedAccessService,
    private router: Router,
  )
  { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let user: UserLogged = this.reservedAccessService.getUserBasicDataFromLocalStorage();

      if (!user.externalAccount) {
        this.router.navigate(['404']);
        return false;
      }

      return true;
  }

}
