import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Anagrafica } from 'src/app/pages/anagrafica/anagrafica';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class AnagraficaUtenteService {
  linkBase: string = "anagraficaUtente";
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  getItem(): Observable<Anagrafica> {
    return this.http.get<Anagrafica>(this.apiUrl)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }
}
