import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DominiUtenteElencoComponent } from './domini-utente-elenco/domini-utente-elenco.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DominiModule } from 'src/app/pages/domini/domini.module';
import { ModalRinnovoDominioUtenteComponent } from './domini-utente-elenco/modal-rinnovo-dominio-utente/modal-rinnovo-dominio-utente.component';



@NgModule({
  declarations: [
    DominiUtenteElencoComponent,
    ModalRinnovoDominioUtenteComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    DominiModule
  ]
})
export class DominiUtenteModule { }
