import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnagraficaService } from '../anagrafica.service';

@Component({
  selector: 'app-anagrafica-elenco',
  templateUrl: './anagrafica-elenco.component.html',
  styleUrls: ['./anagrafica-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnagraficaElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  openVisualizzaDettagli: number = -1;

  constructor(
    private service: AnagraficaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.denominazione}` },
          { columnDef: 'email', header: 'Email',    cell: (element: any) => `${element.email}` },
          { columnDef: 'domini', header: 'N. domini',    cell: (element: any) => `${element.elementiAssociati}` },
          { columnDef: 'descStatoAccount', header: 'Stato account',    cell: (element: any) => `${element.descStatoAccount}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
          ,customButtons: {
            a1_ordini: {
              type: 'custom',
              testo: 'Ordini',
              colorClass: 'info',
              icon: 'mdi mdi-cart',
              actionTooltip: 'Visualizza ordini',
              onClickFunction: this.mostraOrdiniAnagrafica.bind(this),
              condizione: 'ordiniPresenti',
              hideText: true,
              ML: true
            }
          }
        }
      });
  }

  ngOnInit(): void {
  }

  statisticheUtente(idAnagrafica) {
    this.openVisualizzaDettagli = idAnagrafica;
  }

  resetOpenIdModal() {
    this.openVisualizzaDettagli = -1;
  }

  mostraOrdiniAnagrafica(idAnagrafica) {
//    this.ordiniService.filtraPerAnagrafica = idAnagrafica;
    this.router.navigate(['/ordini']);

  }
}
